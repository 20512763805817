import React from 'react';
import { Link, LinkProps } from 'react-router';

type DefaultLinkProps = React.PropsWithoutRef<LinkProps>;

export const DefaultLink: React.FC<DefaultLinkProps> = ({
  children,
  onClick,
  ...props
}: DefaultLinkProps) => {
  return (
    <Link
      {...props}
      className="text-green-800 underline text-xl"
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
