import { unwrap } from './common';
import axios from 'axios';

export enum ProjectPictureKeys {
  PROJECT_PICTURE = 'PROJECT_PICTURE',
}

export type ProjectPicture = {
  id: number;
  picture: string;
};

const queries = {
  async getProjectPicture(projectId: number) {
    if (!projectId) {
      return null;
    }
    const res = await axios.get<ProjectPicture>(`/project/pic/${projectId}/`);
    return unwrap(res);
  },
};

const ProjectPictureApi = {
  queries,
};

export default ProjectPictureApi;
