import {
  MonthInfo,
  StampType,
  Timestamp,
  TimestampDate,
  WorkingState,
} from '~/types';
import {
  MutationOptions,
  QueryOptions,
  unwrap,
  useApiMutation,
} from './common';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import * as dateUtil from '../utils/dateUtil';
import { useIonToast } from '@ionic/react';
import { useTranslate } from '~/i18n/translate';
import { useNavigateToRunningAny } from '~/utils/routingUtils';
import { lastDayOfWeek, setDay } from 'date-fns';

type GetDatesRequest = {
  start?: Date | null;
  end?: Date | null;
};

export function useDates<T = TimestampDate[]>(
  req?: GetDatesRequest,
  options?: QueryOptions<TimestampDate[], T>,
) {
  return useQuery({
    ...options,
    queryKey: ['dates', req],
    queryFn: () =>
      axios
        .post<TimestampDate[]>(
          '/dates/',
          req?.start && req?.end
            ? {
                start: dateUtil.toApiFormat(req.start),
                end: dateUtil.toApiFormat(req.end),
              }
            : { start: null, end: null },
        )
        .then(unwrap),
  });
}

export function useCurrentTimstStamp(
  req?: GetDatesRequest,
  options?: Omit<QueryOptions<TimestampDate[], Timestamp>, 'select'>,
) {
  return useDates(req, {
    ...options,
    select: (dates) => {
      return selectTimestamp(dates);
    },
  });
}

export function useWorkingState(
  req?: GetDatesRequest,
  options?: Omit<QueryOptions<TimestampDate[], WorkingState>, 'select'>,
) {
  return useDates(req, {
    ...options,
    select: (dates): WorkingState => {
      switch (selectTimestamp(dates)?.type) {
        case StampType.WORK:
          return WorkingState.WORKING;
        case StampType.PAUSE:
          return WorkingState.PAUSE;
        default:
          return WorkingState.NOTHING;
      }
    },
  });
}

export function useCheckEndWork() {
  return useApiMutation<number, number>({
    mutationFn: (employeeId: number) =>
      axios
        .get<number>(`/timestamp/checkEndWork/${employeeId}/`)
        .then((res) => res.data),
  });
}

export function useCheckEndPause() {
  return useApiMutation<number, number>({
    mutationFn: (employeeId: number) =>
      axios
        .get<number>(`/timestamp/checkEndPause/${employeeId}/`)
        .then((res) => res.data),
  });
}

export function useStartWork(options?: MutationOptions<TimestampDate>) {
  const queryClient = useQueryClient();
  return useApiMutation({
    ...options,
    mutationFn: async () => {
      const { data } = await axios.post<TimestampDate>('/timestamp/', {
        type: StampType.WORK,
      });
      return data;
    },
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({ queryKey: ['dates'] });
      void queryClient.invalidateQueries({ queryKey: ['running'] });
      options?.onSuccess?.(data, variables, context);
    },
    invalidateKeys: ['dates'],
  });
}

export function usePauseWork(options?: MutationOptions<TimestampDate>) {
  const queryClient = useQueryClient();
  return useApiMutation({
    ...options,
    mutationFn: async () => {
      const { data } = await axios.post<TimestampDate>('/timestamp/', {
        type: StampType.PAUSE,
      });
      return data;
    },

    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({ queryKey: ['dates'] });
      void queryClient.invalidateQueries({ queryKey: ['running'] });
      options?.onSuccess?.(data, variables, context);
    },
    invalidateKeys: ['dates'],
  });
}

export function useEndWork(options?: MutationOptions<TimestampDate>) {
  const queryClient = useQueryClient();
  const t = useTranslate();
  const [present] = useIonToast();
  const navigateToRunningAny = useNavigateToRunningAny();
  return useApiMutation({
    ...options,
    mutationFn: async () => {
      const { data } = await axios.post<TimestampDate>('/timestamp/', {
        type: StampType.WORK,
        end: true,
      });
      return data;
    },
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({ queryKey: ['dates'] });
      void queryClient.invalidateQueries({ queryKey: ['running'] });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error) => {
      if (error.status === 409) {
        void present({
          message: t('timeTracking.endJobFirst'),
          duration: 1000,
          color: 'danger',
        });
        navigateToRunningAny();
      }
    },
    invalidateKeys: ['dates'],
  });
}

function selectTimestamp(dates: TimestampDate[]) {
  return dates
    .flatMap((date) => date.timestamps)
    .filter(Boolean)
    .find((stamp) => !stamp.end);
}

export function useTimetrackingInfo<T = MonthInfo>({
  start,
  end,
  ...options
}: QueryOptions<MonthInfo, T> & GetDatesRequest) {
  const req =
    start && end
      ? {
          start: dateUtil.toApiFormat(start),
          end: dateUtil.toApiFormat(end),
        }
      : {
          start: dateUtil.toApiFormat(
            setDay(new Date(), 1, { weekStartsOn: 1 }),
          ),
          end: dateUtil.toApiFormat(
            lastDayOfWeek(new Date(), { weekStartsOn: 1 }),
          ),
        };
  return useQuery({
    ...options,
    queryKey: ['timetracking', start, end],
    queryFn: () =>
      axios
        .get(`/dates/employee/info/?start=${req.start}&end=${req.end}`)
        .then(unwrap),
  });
}
