import React from 'react';
import routes from '../constants/routes.json';
import { AnyType, NewJob, RunningNewJob } from '~/types';
import { DefaultRunningAnyTile } from './defaultUIComponents';
import { useRunningAnys } from '~/api/JobApi';
import { useEmployeeId } from '~/state/auth';
import { isRunningNewJob } from '~/utils/runningAny';
import { useResumeNewJob, useStartNewJob } from '~/api/NewJobApi';
import { useNavigate } from 'react-router';

type NewJobProps = {
  newJob: NewJob;
  isStartable?: boolean;
};

const NewJobTile: React.FC<NewJobProps> = ({ newJob, isStartable }) => {
  const navigate = useNavigate();
  const employeeId = useEmployeeId();
  const startNewJobRoute = routes.RUN_NEW_JOB.replace(':new_job', newJob.id);

  const onSuccess = () => {
    return navigate(startNewJobRoute);
  };

  const { data: runningNewJob } = useRunningAnys({
    employeeId,
    select: (ra) =>
      ra.find((rj) => isRunningNewJob(rj) && rj.new_job.id === newJob.id) as
        | RunningNewJob
        | undefined,
  });

  const { mutate: startNewJob } = useStartNewJob({ onSuccess });
  const { mutate: resumeNewJob } = useResumeNewJob({ onSuccess });

  const runNewJobOnClick = () => {
    if (runningNewJob?.status == 'R') {
      return void navigate(startNewJobRoute);
    }
    if (runningNewJob) {
      return resumeNewJob({
        runningNewJobId: Number(runningNewJob.id),
      });
    }

    startNewJob({ newJobId: Number(newJob.id) });
  };

  return (
    <DefaultRunningAnyTile
      anyType={AnyType.newJob}
      name={newJob.name}
      description={newJob.description}
      runRunningAny={runNewJobOnClick}
      isStartable={isStartable}
    />
  );
};

export default NewJobTile;
