import {
  IonContent,
  IonFooter,
  IonIcon,
  IonLoading,
  IonPage,
  IonToolbar,
  IonButton,
} from '@ionic/react';
import { arrowForward, image } from 'ionicons/icons';
import { useMemo, useState } from 'react';

import { Button, FlexColumn } from '~/components/defaultUIComponents';
import JobsInProject from '../components/JobsInProject';
import OptionsInProject from '../components/OptionsInProject';
import OrdersInProject from '../components/OrdersInProject';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '~/i18n/translate';
import { useEmployeeId } from '~/state/auth';
import { useCreateTodos } from '~/api/DayplanApi';
import routes from '../constants/routes.json';
import { useDayplanStore } from '~/state/dayplan';
import { useSummedOrderedProject } from '~/api/OrderingApi';
import ProjectPicturePopup from '~/components/ProjectPicturePopup';
import { useNavigate, useParams } from 'react-router';

function ProjectPage() {
  const translate = useTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = Number(id);
  const { data: currentSummedOrderProject, isLoading } =
    useSummedOrderedProject({ projectId, enabled: !!projectId });

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { mutate: createTodos } = useCreateTodos({
    onSuccess: async () => {
      await navigate(routes.DAY_PLAN);
      useDayplanStore.setState(useDayplanStore.getInitialState());
    },
  });

  const { todos, isEditingTodos } = useDayplanStore((state) => ({
    todos: state.todos,
    isEditingTodos: state.todos.length > 0,
  }));

  const employeeId = useEmployeeId();

  const displayedProjectName = useMemo(
    () => currentSummedOrderProject?.project?.name || '',
    [currentSummedOrderProject],
  );

  return (
    <IonPage>
      <Toolbar>{translate('project.toolbar.title')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn>
          <div className="flex justify-between items-center p-4">
            <span className="text-xl font-bold">
              {translate('project.project')}: {displayedProjectName}
            </span>
            <IonButton onClick={() => setIsPopupOpen(true)}>
              <IonIcon slot="start" icon={image} />
              {translate('project.main_image')}
            </IonButton>
          </div>
          <OrdersInProject summedProjectOrder={currentSummedOrderProject} />
          <JobsInProject
            projectId={currentSummedOrderProject?.project?.id}
            hasOptions={!!currentSummedOrderProject?.project?.options?.length}
          />
          <OptionsInProject summedProjectOrder={currentSummedOrderProject} />
        </FlexColumn>
      </IonContent>
      {isEditingTodos && (
        <IonToolbar>
          <IonFooter>
            <Button onClick={() => createTodos({ todos, employeeId })}>
              {translate('project.moveInDayplan')}
              <IonIcon icon={arrowForward} />
            </Button>
          </IonFooter>
        </IonToolbar>
      )}
      <ProjectPicturePopup
        projectId={projectId}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
    </IonPage>
  );
}

export default ProjectPage;
