import { createBrowserRouter, Outlet, redirect } from 'react-router';
import Authenticate from './pages/Authenticate';
import routes from './constants/routes.json';
import QRCodePage from './pages/CodeScannerPage';
import { AuthStore } from './state/auth';
import TimeTracking from './pages/TimeTracking';
import Orders from './pages/Orders';
import ProjectPage from './pages/Project';
import RunJob from './pages/RunJob';
import Dayplan from './pages/Dayplan';
import Weekplan from './pages/Weekplan';
import Routines from './pages/Routines';
import RunRoutine from './pages/RunRoutine';
import RunningJobs from './pages/RunningJobs';
import Overseer from './pages/Overseer';
import { RoleCode } from './types';
import SpecialJob from './pages/SpecialJob';
import RunSpecialJob from './pages/RunSpecialJob';
import CreateNewJob from './pages/CreateNewJob';
import RunNewJob from './pages/RunNewJob';
import Settings from './pages/Settings';
import Menu from './components/Menu';

export const createRouter = (authState: AuthStore) =>
  createBrowserRouter([
    {
      id: 'authenticated',
      loader: () => {
        if (!authState.authenticated) {
          return redirect(routes.AUTHENTICATE);
        }
        return null;
      },
      element: (
        <>
          <Menu />
          <Outlet />
        </>
      ),
      children: [
        {
          path: routes.TIME_TRACKING,
          element: <TimeTracking />,
        },
        {
          path: routes.ORDERS,
          element: <Orders />,
        },
        {
          path: routes.PROJECT,
          element: <ProjectPage />,
        },
        {
          path: routes.RUN_JOB,
          element: <RunJob />,
        },
        {
          path: routes.DAY_PLAN,
          element: <Dayplan />,
        },
        {
          path: routes.WEEK_PLAN,
          element: <Weekplan />,
        },
        {
          path: routes.ROUTINES,
          element: <Routines />,
        },
        {
          path: routes.RUN_ROUTINE,
          element: <RunRoutine />,
        },
        {
          path: routes.RUNNING_JOBS,
          element: <RunningJobs />,
        },
        {
          loader: () => {
            const hasOverseerRole = authState.roles?.some(
              (it) => it.role.code === RoleCode.OVERSEER,
            );
            return hasOverseerRole ? null : redirect(routes.TIME_TRACKING);
          },
          path: routes.JOBS_COLLEAGUES,
          element: <Overseer />,
        },
        {
          path: routes.SPECIAL_JOBS,
          element: <SpecialJob />,
        },
        {
          path: routes.RUN_SPECIAL_JOBS,
          element: <RunSpecialJob />,
        },
        { path: routes.CREATE_JOB, element: <CreateNewJob /> },
        { path: routes.RUN_NEW_JOB, element: <RunNewJob /> },
        { path: routes.SETTINGS, element: <Settings /> },
      ],
    },
    {
      id: 'login',
      loader: () => {
        if (authState.authenticated) {
          return redirect(routes.TIME_TRACKING);
        }
        return null;
      },
      path: routes.AUTHENTICATE,
      element: <Authenticate />,
    },
    {
      id: 'qr-code',
      path: routes.CODE_SCANNING,
      element: <QRCodePage />,
      loader: () => {
        const isLogoutAction = authState.flow === 'logout';
        const isLoggedIn = authState.authenticated;

        if (isLoggedIn && !isLogoutAction) {
          return redirect(routes.TIME_TRACKING);
        }
        return null;
      },
    },
    {
      id: 'catch-all',
      path: '*',
      loader: () => redirect(routes.AUTHENTICATE),
    },
  ]);
