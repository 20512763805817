import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import React, { createRef, useEffect } from 'react';
import routes from '../constants/routes.json';
import { AppPage, appPages } from '~/menuRoutes';
import useIsFeatureFlagActive from '../utils/featureFlag';
import { Button, FlexColumn, Text } from './defaultUIComponents';
import { useSpecialJobs } from '~/api/SpecialJobApi';
import { useAuthStore, useEmployeeId } from '~/state/auth';
import { RoleCode } from '~/types';
import { NavLink } from 'react-router';
import { useMenuContext } from '~/AppContext';

type Props = {
  appPage: AppPage;
  open: boolean;
};

const AppPageComponent = ({ appPage, open }: Props) => {
  const employeeId = useEmployeeId();
  const { data: specialJobs = [] } = useSpecialJobs({
    employeeId,
    enabled: open,
  });

  return useIsFeatureFlagActive(appPage.featureFlag) ? (
    <IonMenuToggle autoHide={false}>
      <NavLink to={appPage.url}>
        {(props) => (
          <IonItem
            className={props.isActive ? 'selected' : ''}
            routerLink={appPage.url}
            routerDirection="back"
            lines="none"
            detail={false}
          >
            <IonIcon slot="start" icon={appPage.iosIcon} />
            <Text className="text-xl">{appPage.title}</Text>
            {appPage.url === routes.SPECIAL_JOBS && specialJobs.length > 0 ? (
              <Button
                style={{ '--background': 'red' }}
                className="w-5"
                shape="round"
              >
                {specialJobs.length}
              </Button>
            ) : null}
          </IonItem>
        )}
      </NavLink>
    </IonMenuToggle>
  ) : null;
};

const Menu: React.FC = () => {
  const { authenticated: isLoggedIn, company_name: companyName } =
    useAuthStore();

  const hasOverseerRole = useAuthStore(
    (store) => !!store.roles?.find((r) => r.role.code === RoleCode.OVERSEER),
  );

  const ref = createRef<HTMLIonMenuElement>();

  const { open, setOpen } = useMenuContext();

  useEffect(() => {
    ref.current
      ?.isOpen()
      .then((isOpen) => {
        if (isOpen && !open) return ref.current?.close();
        if (!isOpen && open) return ref.current?.open();
        return null;
      })
      .catch((e) => console.error(e));
  }, [open]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <IonMenu
      contentId="main"
      type="overlay"
      ref={ref}
      onIonDidOpen={() => setOpen(true)}
      onIonDidClose={() => setOpen(false)}
    >
      <IonContent>
        <IonList id="inbox-list">
          <FlexColumn className="mb-2">
            <Text className="ml-1 text-xl">Its4-Plan</Text>
            <Text className="ml-1 text-xl">{companyName}</Text>
          </FlexColumn>
          {appPages(hasOverseerRole).map((appPage, index) => (
            <AppPageComponent key={index} appPage={appPage} open={open} />
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
