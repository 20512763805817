import { IonCard, IonCardHeader, IonItem } from '@ionic/react';
import React, { useMemo } from 'react';
import { RunBtn } from './RunBtn';
import routes from '../../constants/routes.json';
import { FlexColumn } from './Layout';
import { Text } from './Typography';
import { useTranslate } from '../../i18n/translate';
import { AnyType } from '~/types';
import { useLocation } from 'react-router';

interface DefaultRunningAnyTileProps {
  anyType: AnyType;
  name: string;
  description: string;
  runRunningAny: () => void;
  durationString?: string;
  isStartable?: boolean;
  amount?: number;
  disabled?: boolean;
}

export const DefaultRunningAnyTile: React.FC<DefaultRunningAnyTileProps> = ({
  anyType,
  name,
  description,
  runRunningAny,
  durationString = '',
  isStartable,
  amount,
  disabled,
}: DefaultRunningAnyTileProps) => {
  const translate = useTranslate();
  const location = useLocation();

  const anyTypeString = useMemo(() => {
    if (location.pathname === routes.RUNNING_JOBS) {
      let returnString = '';
      if (anyType === AnyType.newJob) {
        returnString = translate('createNewJob.toolbar');
      } else if (anyType === AnyType.routine) {
        returnString = translate('routines.singular');
      } else if (anyType === AnyType.specialJob) {
        returnString = translate('specialJob.singular');
      } else {
        returnString = translate('jobTile.singular');
      }
      returnString += ': ';
      return returnString;
    } else return '';
  }, [anyType]);

  return (
    <IonCard className="mt-2 mb-1">
      <IonCardHeader>
        <IonItem className="mr-1 ml-1 relative" lines="none">
          <FlexColumn>
            <Text className="text-lg break-words mr-7">
              {`${anyTypeString}${name}`}
            </Text>
            {isStartable ? (
              <RunBtn
                className="primary absolute top-0 right-0"
                onClick={runRunningAny}
                disabled={disabled}
              />
            ) : null}
            {durationString !== '' && anyType === AnyType.specialJob ? (
              <Text>
                {translate('specialJobTile.duration') + ' '}
                {durationString}
              </Text>
            ) : null}
            <Text className="mt-1">jobTile.description</Text>
            <Text className="break-words">{description}</Text>
            {amount && (
              <Text>
                {translate('amount')} {amount}
              </Text>
            )}
          </FlexColumn>
        </IonItem>
      </IonCardHeader>
    </IonCard>
  );
};
