import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  LoginCodeScanner,
  LogoutCodeScanner,
} from '~/components/defaultUIComponents/CodeScanner/CodeScanner';
import { useTranslate } from '~/i18n/translate';
import { useAuthStore } from '~/state/auth';

const QRCodePage: React.FC = () => {
  const translate = useTranslate();
  const isLogoutAction = useAuthStore((store) => store.flow === 'logout');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{translate('2faktor.pleaseScan')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!isLogoutAction ? <LoginCodeScanner /> : <LogoutCodeScanner />}
      </IonContent>
    </IonPage>
  );
};

export default QRCodePage;
