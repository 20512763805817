import React, { useEffect, useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import ProjectPictureApi from '../api/ProjectPictureApi';
import ProjectPicture from './ProjectPicture';
import { useTranslate } from '~/i18n/translate';
import { close } from 'ionicons/icons';

interface ProjectPictureData {
  picture: string | null;
}

const ProjectPicturePopup: React.FC<{
  projectId: number;
  isOpen: boolean;
  onClose: () => void;
}> = ({ projectId, isOpen, onClose }) => {
  const [projectPicture, setProjectPicture] =
    useState<ProjectPictureData | null>(null);
  const translate = useTranslate();

  useEffect(() => {
    if (projectId && isOpen) {
      void ProjectPictureApi.queries
        .getProjectPicture(projectId)
        .then((data: ProjectPictureData | null) => {
          setProjectPicture(data);
        });
    }
  }, [projectId, isOpen]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{translate('project.main_image')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onClose}>
                <IonIcon
                  icon={close}
                  style={{ color: 'red', fontSize: '24px' }}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <ProjectPicture
            initialImage={projectPicture?.picture || null}
            projectId={projectId}
          />
        </div>
      </div>
    </IonModal>
  );
};

export default ProjectPicturePopup;
