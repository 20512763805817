import { IonItem } from '@ionic/react';
import React, { useMemo } from 'react';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { RoleCode } from '~/types';
import {
  Button,
  DefaultLink,
  FlexColumn,
  FlexRow,
  Text,
} from './defaultUIComponents';
import { OrderDoneModal } from './OrderDoneModal';
import { ShowForRole } from './ShowForRole';
import { useDisclosure } from '~/utils/disclosure';
import { useOrderedProjectDone } from '~/api/OrderingApi';
import { OrderedProject } from '@its4plan/ordering';

type WeekplanEntryInput = {
  orderedProject: OrderedProject;
  sortByName?: boolean;
};

const WeekplanTile: React.FC<WeekplanEntryInput> = ({
  orderedProject,
  sortByName,
}) => {
  const translate = useTranslate();
  const modal = useDisclosure();
  const { isPending } = useOrderedProjectDone();

  const urlToProject = useMemo(
    () => routes.PROJECT.replace(':id', orderedProject.project.foreignId.toString()),
    [orderedProject],
  );
  const optionsAsString = useMemo(() => {
    let returnString = '';
    const options = orderedProject.options;
    options.forEach((op, index) => {
      returnString += op.project.name;
      if (index !== options.length - 1) {
        returnString += ', ';
      }
    });
    return returnString;
  }, [orderedProject]);

  return (
    <IonItem>
      <FlexColumn className="pl-3">
        <FlexRow justifyContent="between">
          <DefaultLink to={urlToProject}>
            <Text>
              {translate('weekplanTile.project') +
                ' ' +
                orderedProject.project.name}
            </Text>
          </DefaultLink>
          <Text className="mr-2">
            {sortByName
              ? translate('weekplanTile.Prio') + ': ' + orderedProject.priority
              : ''}
          </Text>
        </FlexRow>
        {orderedProject.comment && (
          <FlexRow>
            <Text>weekplanTile.comment</Text>
            <Text>{orderedProject.comment}</Text>
          </FlexRow>
        )}
        <Text>
          {translate('weekplanTile.options') + ' '}
          {orderedProject.options.length === 0
            ? translate('weekplanTile.noOptions')
            : optionsAsString}
        </Text>
        <FlexRow justifyContent="between">
          <Text>
            {translate('weekplanTile.amount') + ' ' + orderedProject.amount}
          </Text>
          <Text>
            {translate('weekplanTile.doneAmount') +
              ' ' +
              orderedProject.doneAmount}
          </Text>
          <Text />
        </FlexRow>
        <Text className="mb-2">
          {translate('weekplanTile.companyName') + ' '}
          {orderedProject.orderInformation.customerName || ''}
        </Text>
        <ShowForRole role={RoleCode.PROJECT_FINISHER}>
          <Button disabled={isPending} onClick={modal.open}>
            {translate('orderTile.done')}
          </Button>
        </ShowForRole>
        <OrderDoneModal
          isOpen={modal.isOpen}
          close={modal.close}
          orderProject={orderedProject}
        />
      </FlexColumn>
    </IonItem>
  );
};

export default WeekplanTile;
