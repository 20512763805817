import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react';
import { FC, PropsWithChildren } from 'react';
import { useEmployeeId } from '~/state/auth';
import { FlexColumn, FlexRow, Text } from '../defaultUIComponents';
import { TimeTrackingButtons } from './TimeTrackingButtons';
import { RenderRunningAny } from './RenderRunningAny';
import { useRunningAnys } from '~/api/JobApi';
import { useMenuContext } from '~/AppContext';

const Toolbar: FC<PropsWithChildren> = ({ children }) => {
  const employeeId = useEmployeeId();
  const { data: runningAny } = useRunningAnys({
    employeeId,
    select: (ra) => ra.find((r) => r.status === 'R'),
  });

  const { toggle } = useMenuContext();

  return (
    <IonHeader>
      <IonToolbar className="pb-2">
        <FlexColumn>
          <FlexRow>
            <IonButtons slot="start">
              <IonMenuButton autoHide={false} onClick={toggle} />
            </IonButtons>
            <FlexRow justifyContent="center" className="items-center">
              <Text textSize="2xl" className="break-words font-bold text-2xl">
                {children}
              </Text>
            </FlexRow>
          </FlexRow>
          <FlexColumn>
            <RenderRunningAny runningAny={runningAny} />
            <TimeTrackingButtons />
          </FlexColumn>
        </FlexColumn>
      </IonToolbar>
    </IonHeader>
  );
};

export default Toolbar;
