import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { closeSharp } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslate } from '~/i18n/translate';
import {
  AggregatedOrderedProject,
  useOrderedProjectDone,
} from '~/api/OrderingApi';
import { OrderedProject } from '@its4plan/ordering';

interface OrderDoneModalProps {
  isOpen: boolean;
  close: () => void;
  orderProject: AggregatedOrderedProject | OrderedProject | undefined;
}

export const OrderDoneModal: React.FC<OrderDoneModalProps> = ({
  isOpen,
  close,
  orderProject,
}) => {
  const [show] = useIonToast();
  const translate = useTranslate();

  const { mutate: orderDone } = useOrderedProjectDone({
    onSuccess: () => {
      void show({
        message: translate('ordertile.done'),
        duration: 5000,
        color: 'success',
      });
      close();
    },
    onError: () => {
      void show({
        message: translate('ordertile.error'),
        duration: 5000,
        color: 'danger',
      });
    },
  });
  const [amount, setAmount] = useState(1);
  const [comment, setComment] = useState('');

  const onModalSubmit = () => {
    if (orderProject) {
      orderDone({
        id: Number(orderProject.id),
        orderedProjectDoneRequest: {
          amount,
          comment,
        },
      });
    }
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar className="pr-2">
          <IonTitle>{translate('orderTile.headerModal')}</IonTitle>
          <IonIcon
            icon={closeSharp}
            slot="end"
            size="xl"
            onClick={() => close()}
            className="text-2xl"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="px-2 py-1">
          <IonInput
            type="number"
            inputMode="numeric"
            label={translate('orderTile.amount.label')}
            labelPlacement="floating"
            value={amount}
            onIonChange={(e) => setAmount(parseInt(e.detail.value ?? '1'))}
            min="1"
            max={`${orderProject?.amount ?? 1}`}
            required
          />
          <IonTextarea
            value={comment}
            rows={3}
            label={translate('orderTile.comment')}
            labelPlacement="floating"
            onIonChange={(e) => setComment(e.detail.value || '')}
          />
        </div>
      </IonContent>
      <IonFooter className="fixed bottom-0 left-0 right-0">
        <IonToolbar>
          <IonButton disabled={!amount} expand="full" onClick={onModalSubmit}>
            {translate('orderTile.closeModal')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
