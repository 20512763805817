import { LanguageSpec } from '../LanguageSpec';

export const de: LanguageSpec = {
  error: {
    unknown: 'Unbekannter Fehler',
    job: {
      not_found: 'Job nicht gefunden',
      already_running: 'Job läuft bereits',
      max_running_amount: 'Maximale Anzahl an laufenden Jobs erreicht',
    },
    running_job: {
      took_too_long: 'Job konnte nicht beendet werden.',
      reg_too_long: 'Job konnte nicht beendet werden.',
    },
  },
  close: 'Schließen',
  amount: 'Menge:',
  'authenticate.login': 'Anmelden',
  'authenticate.user-name': 'Nutzername',
  'authenticate.password': 'Passwort',
  'authenticate.wrongInput':
    'Diese Kombination aus Nutzername und Passwort sind uns nicht bekannt',
  'authenticate.wrongQRCode': 'Der QR Code ist falsch',
  'authenticate.abortScanning': 'Das Scannen wurde abgebrochen',
  'authenticate.chooseCompany': 'Unternehmen auswählen',
  'authenticate.searchCompany.placeholder': 'Unternehmen suchen',
  'authenticate.searchCompany.helper': 'Mindestens 3 Zeichen eingeben',
  'durationPicker.confirm': 'Bestätigen',
  cameraAlertHeader: 'Fehler',
  cameraAlertMessage: 'Es wurde keine Kamera erkannt',
  'modalSelect.header': 'Auswahl',
  'modalSelect.submit': 'Auswählen',
  'modalSelect.placeholder': 'Auswahl',

  'changeRequestModal.type.JOB_STEP_NUMBER': 'Schrittnummer ändern',
  'changeRequestModal.type.DELETE': 'Schritt löschen',
  'changeRequestModal.type.COPY_TO_PROJECT': 'Schritt kopieren nach',
  'changeRequestModal.type.COMBINE_STEPS': 'Schritte zusammenfügen',
  'changeRequestModal.type.INFO': 'Information',
  'changeRequestModal.type.ADD_TEXT': 'Beschreibung ändern',
  'changeRequestModal.chooseType': 'Änderungswunsch:',
  'changeRequestModal.stepNumber.label': 'Neue Schrittnummer:',
  'changeRequestModal.copyToProject.label': 'Projektname:',
  'changeRequestModal.combineWith.label': 'Schrittname:',
  'changeRequestModal.info.label': 'Information:',
  'changeRequestModal.addText.label': 'Beschreibung ändern:',
  'changeRequestModal.submitButton.text': 'Abschicken',

  'createNewJob.errorMessage': 'Fehler beim Erstellen des Jobs',
  'createNewJob.projectNotFound': 'Projekt konnte nicht gefunden werden',
  'createNewJob.toolbar': 'Neuer Job',
  'createNewJob.projektJob': 'Projektjob',
  'createNewJob.specialJob': 'Sonderjob',
  'createNewJob.goBack': 'Zurück',
  'createNewJob.name': 'Jobname',
  'createNewJob.project': 'Projekt',
  'createNewJob.description': 'Beschreibung',
  'createNewJob.create': 'Starten',
  'createNewJob.startTimetrackingFirst':
    'Bevor ein neuer Job erstellt werden kann, muss die Zeiterfassung gestartet werden',
  'createNewJob.specialJob.optionLabel': 'Sonderjob',
  'createNewJob.durationInMinutes': 'Dauer (in Minuten)',
  'createNewJob.amount': 'Anzahl',

  'dayplan.toolbar': 'Tagesplan',

  'jobsColleagues.toolbar': 'Kollegen',

  'jobsInOrder.jobs': 'Jobs',

  'jobsInProject.select.label': 'Nach Abteilung filtern:',
  'jobsInProject.thereAreOptions': 'Optionen: siehe unten',

  'jobTile.singular': 'Job',
  'jobTile.errorMessage': 'Der Job konnte nicht gestartet werden.',
  'jobTile.stepNumber': 'Schritt: ',
  'jobTile.description': 'Beschreibung: ',
  'jobTile.duration': 'Dauer: ',
  'jobTile.projectName': 'Projekt: ',
  'jobTile.remainingAmount': 'Noch zu erledigen: ',
  'jobTile.doneAmount': 'Bereits erledigt: ',
  'jobTile.totalAmount': 'Insgesamt: ',
  'jobTile.todoAmount': 'Im Tagesplan: ',
  'jobTile.removeTodoErrorMessage':
    'Todo konnte nicht vom Tagesplan entfernt werden',
  'jobTile.deleteFromDayplan': 'Entfernen',
  'jobTile.InputCopyToDayplan.placeholder': 'Menge eingeben',
  'jobTile.checkbox.label': 'Für Tagesplan auswählen:',
  'jobTile.changeRequest.text': 'Änderungswunsch',

  'menu.logout': 'Abmelden',

  'menuRoutes.timeTracking': 'Zeiterfassung',
  'menuRoutes.orders': 'Aufträge',
  'menuRoutes.dayplan': 'Tagesplan',
  'menuRoutes.weekplan': 'Wochenplan',
  'menuRoutes.routines': 'Routinen',
  'menuRoutes.newJob': 'Neuer Job',
  'menuRoutes.runningJobs': 'Laufende Jobs',
  'menuRoutes.createSpecialJob': 'Neuer Sonderjob',
  'menuRoutes.specialJobs': 'Sonderjobs',
  'menuRoutes.settings': 'Einstellungen',
  'menuRoutes.jobsColleagues': 'Kollegen',

  maxNumberOfRunningJobs: 'Maximale Anzahl an laufenden Jobs ist erreicht',

  'modal.abort': 'Abbrechen',
  'modal.abort.yes': 'Ja',
  'modal.abort.no': 'Nein',

  'newJobs.toolbar': 'Neue Jobs',
  'newJobs.selectDepartment': 'Nach Abteilung filtern',
  'newJobs.noNewJobs': 'Keine neuen Jobs vorhanden',

  'newJobTile.errorMessage': 'Es gab ein Fehler beim Erstellen des Sonderjobs',

  'optionsInProject.option': 'Option:',

  'orderInProject.orders': 'Aufträge',
  'orderInProject.summedAmount': 'Stück:',

  'orders.toolbar': 'Aufträge',

  'orderTile.amount': 'Bestellt:',
  'orderTile.done_amount': 'Davon erledigt:',
  'orderTile.options': 'Optionen:',
  'orderTile.done': 'Erledigt (Menge)',
  'orderTile.headerModal': 'Auftrag erledigt',
  'orderTile.closeModal': 'Erledigt',
  'orderTile.amount.label': 'Menge',
  'orderTile.comment': 'Kommentar:',

  'pictureViewer.headerText': 'Bilder',
  'pictureViewer.submitText': 'Zurück',
  'pictureViewer.loadingText': 'Bilder werden geladen',
  'project.main_image': 'Projektbild',
  'project.errorMessage': 'Das Projekt konnte nicht gestartet werden',
  'project.notAllTodosHaveAnAmount':
    'Anzahl bei allen ausgewählten Jobs eingeben',
  'project.moveInDayplan': 'In Tagesplan verschieben',
  'project.toolbar.title': 'Projektaufträge',
  'project.project': 'Projekt',
  'project.toolbar.orderTitle': 'Projektauftrag',
  'project.amount.abbreviation': 'Stk.',
  'project.companyName': 'Kunde: ',

  'projectTile.totalAmount': 'Insgesamt beauftragt:',

  'routines.toolbar': 'Routinen',
  'routines.singular': 'Routine',
  'routines.selectDepartment': 'Nach Abteilung filtern',
  'routines.noRoutines': 'Keine Routinen vorhanden',

  'routineTile.errorMessage': 'Die Routine konnte nicht gestartet werden',
  'routineTile.description': 'Beschreibung:',
  'routineTile.department': 'Department:',
  'routineTile.duration': 'Dauer:',
  'routineTile.start': 'Starten',

  'runAny.cancel.confirm': 'Soll abgebrochen werden?',

  'runJob.jobTookTooLongHeaderText': 'Jobdauer',
  'runJob.jobTookTooLongReasonText': 'Gab es Probleme?',
  'runJob.jobTookTooRegLongHeaderText': 'Jobdauer',
  'runJob.jobTookTooRegLongReasonText': 'Gab es Probleme?',
  'runJob.endJobError': 'Der Job konnte nicht beendet werden.',
  'runJob.improvementText': 'Jobverbesserungen',
  'runJob.closeModal': 'Senden',
  'runJob.stepNumber': 'Schritt:',
  'runJob.remaining': 'Noch offen:',
  'runJob.summedAmount': 'Beauftragt (Wochenplan):',
  'runJob.doneAmount': 'Erledigt:',
  'runJob.important': 'Wichtige Nachricht:',
  'runJob.amount.label': 'Menge',
  'runJob.amount.placeholder': 'Menge',
  'runJob.comment.label': 'Neue Jobbeschreibung',
  'runJob.comment.suffix': '(kein Änderungswunsch)',
  'runJob.end': 'Beenden',
  'runJob.jobDescription': 'Beschreibung:',
  'runJob.toolbar.title': 'Laufender Job',
  'runJob.cancel': 'Abbrechen',

  'runNewJob.endNewJobError': 'Der Neue Job konnte nicht beendet werden.',
  'runNewJob.newJobNotFound': 'Fehler',
  'runNewJob.amount': 'Menge',
  'runNewJob.department': 'Abteilung',
  'runNewJob.department.select': 'Abteilung wählen',
  'runNewJob.description': 'Beschreibung',
  'runNewJob.endNewJob': 'Beenden',
  'runNewJob.toolbar.title': 'Laufender Neuer Job',
  'runNewJob.toolbar.description': 'Beschreibung:',
  'runNewJob.cancel': 'Abbrechen',
  'runNewJob.picture.needed': 'Mindestens ein Bild wird benötigt',

  'runningJobs.toolbar': 'Laufende Jobs',

  'runRoutine.errorMessage': 'Die Routine konnte nicht beendet werden',
  'runRoutine.amount': 'Menge',
  'runRoutine.comment': 'Kommentar',
  'runRoutine.end': 'Beenden',
  'runRoutine.routineTookTooLongHeaderText': 'Routinedauer',
  'runRoutine.routineTookTooLongReasonText': 'Gab es Probleme?',
  'runRoutine.routineTookTooRegLongHeaderText': 'Routinedauer',
  'runRoutine.routineTookTooRegLongReasonText': 'Gab es Probleme?',
  'runRoutine.closeModal': 'Senden',
  'runRoutine.improvementText': 'Was könnte an der Routine verbessert\nwerden?',
  'runRoutine.toolbar.title': 'Laufende Routine',
  'runRoutine.cancel': 'Abbrechen',

  'runSpecialJob.errorMessage': 'Der Sonderjob konnte nicht beendet werden',
  'runSpecialJob.description': 'Beschreibung:',
  'runSpecialJob.end': 'Beenden',
  'runSpecialJob.amount': 'Menge',
  'runSpecialJob.toolbar.title': 'Laufender Sonderjob',
  'runSpecialJob.cancel': 'Abbrechen',
  'runSpecialJob.duration': 'Dauer:',
  'runSpecialJob.orderedAmount': 'Bestellmenge:',

  'select.cancelText': 'Abbrechen',
  'select.okText': 'Ok',
  'select.placeholder': 'Klicke hier zum Filtern',
  'select.placeholder.chooser': 'Klicke hier zum Auswählen',
  'select.defaultValue': 'Kein Filter',

  'settings.toolbar': 'Einstellungen',

  'specialJob.toolbar': 'Sonderjobs',
  'specialJob.singular': 'Sonderjob',
  'specialJob.selectDepartment': 'Nach Abteilung filtern',
  'specialJobs.noSpecialJobs': 'Keine Sonderjobs vorhanden',

  'specialJobTile.errorMessage': 'Der Sonderjob konnte nicht gestartet werden',
  'specialJobTile.name': 'Name:',
  'specialJobTile.description': 'Beschreibung:',
  'specialJobTile.duration': 'Dauer:',

  'timeTracking.start': 'Arbeitsbeginn',
  'timeTracking.pause': 'Pause',
  'timeTracking.pauseEnd': 'Pausenende',
  'timeTracking.end': 'Arbeitsende',
  'timeTracking.noOptions.text': 'keine',
  'timeTracking.toolbar': 'Zeiterfassung',
  'timeTracking.endJobFirst':
    'Bitte erst alle Jobs, Routinen und Sonderjobs beenden',
  'timeTracking.workingTime': 'Arbeitszeit:',
  'timeTracking.pauseTime': 'Pausenzeit:',
  'timeTracking.nonWorkingTime': 'Zeit Ohne Arbeitsschritt:',
  'timeTracking.chosenSummedTime.prefix':
    'Arbeitszeit (ohne Pause, im Zeitraum):',
  'timeTracking.chosenSummedTime.suffix': '(im Zeitraum)',
  'timeTracking.chosenSummedTime.defaultSuffix': '(letzte sieben Tage)',
  'timeTracking.expected_time': 'Sollzeit',
  'timeTracking.thisWeek': '(diese Woche):',
  'timeTracking.chosenTime': '(im Zeitraum):',

  'logout.alert.header': 'Hinweis',

  'tookTooLong.actualDuration': 'Benötigte Gesamtzeit:',
  'tookTooLong.demandedDurationPerPiece': 'Sollzeit pro Stück:',
  'tookTooLong.amountDone': 'Erledigte Menge:',
  'tookTooLong.timeTooMuch': 'Überzeit insgesamt:',
  'tookTooLong.timeSuggestion': 'Zeiteinschätzung',
  'tookTooLong.hours': 'Stunden',
  'tookTooLong.minutes': 'Minuten',
  'tookTooLong.seconds': 'Sekunden',
  'toolbar.nonWorkingTime': 'Zeit Ohne Arbeitsschritt',
  'toolbar.pauseTime': 'Pause',
  'toolbar.firstWarning.submitText': 'Verstanden',
  'toolbar.secondWarning.submitText': 'Senden',
  'toolbar.warning.text.firstPart': 'Willkommen am Arbeitsplatz',
  'toolbar.warning.text.secondPart':
    'Gestern lag die Zeit Ohne Arbeitsschritt bei',
  'toolbar.warning.text.thirdPart': 'Minuten.',
  'toolbar.firstWarning.text.secondPart':
    'Bitte zu lange Zeitspannen Ohne Arbeitsschritt vermeiden!',
  'toolbar.secondWarning.input.label': 'Woran liegt das?',

  'weekplan.toolbar': 'Wochenplan',
  'weekplan.sortPrefixtText': 'Sortiert nach:',
  'weekplan.sortByWeek': 'Woche',
  'weekplan.sortByName': 'Name',
  'weekplan.departmentTimes': 'Abteilungszeiten',

  'weekplanTile.project': 'Projekt:',
  'weekplanTile.comment': 'Kommentar:',
  'weekplanTile.options': 'Optionen:',
  'weekplanTile.noOptions': 'keine',
  'weekplanTile.amount': 'Menge:',
  'weekplanTile.companyName': 'Kunde:',
  'weekplanTile.Prio': 'Prio',
  'weekplanTile.doneAmount': 'Erledigt:',
  'weekplanTile.remainingAmount': 'Noch offen:',
  'weekplanTile.checkIn': 'Einchecken',

  'working-state.working': 'Arbeitszeit des Tages',
  'working-state.pause': 'Pausenzeit des Tags',
  '2faktor.pleaseScan': 'Bitte Qr Code Scannen',
  'searchField.search': 'Suchen',

  'yup.required': 'Bitte Wert eingeben',
  'yup.notValid': 'Nicht alle Eingabefelder wurden ausgefüllt',
  'timetracking.from': 'von',
  'timetracking.to': 'bis',
  'authenticate.showPassword': 'Passwort zeigen',
  'job-state.pause': 'Pausiert',
  'job-state.running': 'Laufend',
  'overseer.start': 'Start:',
  'overseer.duration': 'Dauer:',
  'overseer.status.pause': 'Macht Pause',
  'overseer.status.arbeit': 'Arbeitet',
  'overseer.status.notLoggedIn': 'Nicht eingeloggt.',
  'ordertile.done': 'Auftrag fertig gemeldet.',
  'ordertile.error': 'Fehler beim Fertig melden.',
};
