import React, { useState, useEffect } from 'react';
import { useTranslate } from '../i18n/translate';

interface ProjectPictureProps {
  initialImage: string | null;
  projectId: number;
}

const ProjectPicture: React.FC<ProjectPictureProps> = ({ initialImage }) => {
  const translate = useTranslate();
  const [image, setImage] = useState<string | null>(initialImage);

  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {image ? (
        <img
          src={image}
          alt={translate('project.main_image')}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px dashed gray',
          }}
        >
          <span>{translate('project.main_image')}</span>
        </div>
      )}
    </div>
  );
};

export default ProjectPicture;
