import { RunningAny } from '~/types';
import routes from '../constants/routes.json';
import {
  isRunningJob,
  isRunningNewJob,
  isRunningRoutine,
  isRunningSpecialJob,
} from '~/utils/runningAny';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { useRunningAnys } from '~/api/JobApi';
import { useEmployeeId } from '~/state/auth';

export const switchToRunningAnyPage =
  (navigate: NavigateFunction, runningAny: RunningAny | null | undefined) =>
  () => {
    if (runningAny) {
      if (isRunningJob(runningAny)) {
        return navigate(routes.RUN_JOB.replace(':id', runningAny.job.id));
      }
      if (isRunningRoutine(runningAny)) {
        return navigate(
          routes.RUN_ROUTINE.replace(':routine', runningAny.routine.id),
        );
      }
      if (isRunningNewJob(runningAny)) {
        return navigate(
          routes.RUN_NEW_JOB.replace(':new_job', runningAny.new_job.id),
        );
      }
      if (isRunningSpecialJob(runningAny)) {
        return navigate(
          routes.RUN_SPECIAL_JOBS.replace(
            ':specialJob',
            runningAny.special_job.id,
          ),
        );
      }
    }
  };

export const useNavigateToRunningAny = () => {
  const employeeId = useEmployeeId();
  const navigte = useNavigate();
  const { data: runningAny } = useRunningAnys({
    employeeId,
    select: (data) => data?.find((ra) => ra.status === 'R'),
  });
  return React.useMemo(() => {
    return switchToRunningAnyPage(navigte, runningAny);
  }, [navigte, runningAny]);
};
