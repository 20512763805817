import { useCallback, useState } from 'react';
import routes from '../../../constants/routes.json';
import { HybridCodeScanner } from './HybridCodeScanner';
import { useVerifyToken } from '~/api/AuthApi';
import { useIonToast } from '@ionic/react';
import { useTranslate } from '~/i18n/translate';
import { useNavigate } from 'react-router';

export function LoginCodeScanner() {
  const t = useTranslate();
  const [present] = useIonToast();

  const { mutate: verifyToken } = useVerifyToken('login', () =>
    setIsScanning(false),
  );

  const [isScanning, setIsScanning] = useState(true);

  const onSuccess = (qrCode: string) => {
    if (!qrCode) {
      void present({
        message: t('authenticate.wrongInput'),
        duration: 1000,
        color: 'danger',
      });
    }
    verifyToken(qrCode);
  };

  const functionToCallOnAbort = () => {
    void present({
      message: t('authenticate.abortScanning'),
      duration: 1000,
      color: 'danger',
    });
  };

  return (
    <HybridCodeScanner
      onSuccess={onSuccess}
      onAbort={functionToCallOnAbort}
      isScanning={isScanning}
      setIsScanning={setIsScanning}
    />
  );
}

export const LogoutCodeScanner = () => {
  const navigate = useNavigate();
  const [present] = useIonToast();
  const t = useTranslate();

  const [isScanning, setIsScanning] = useState(true);
  const { mutate: verifyToken } = useVerifyToken('logout', () =>
    setIsScanning(false),
  );

  const onSuccess = useCallback((qrCode: string) => {
    verifyToken(qrCode);
  }, []);

  const onAbort = useCallback(() => {
    void present({
      message: t('authenticate.abortScanning'),
      duration: 1000,
      color: 'danger',
    });
    void navigate(routes.TIME_TRACKING);
  }, []);

  return (
    <HybridCodeScanner
      isScanning={isScanning}
      setIsScanning={setIsScanning}
      onSuccess={onSuccess}
      onAbort={onAbort}
    />
  );
};
