import { createRoot } from 'react-dom/client';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setupIonicReact } from '@ionic/react';
import { AppContext } from './AppContext';
import { useAuthStore } from '~/state/auth';

setupIonicReact({
  mode: 'md',
});

void defineCustomElements(window);

const root = createRoot(document.getElementById('root')!);

useAuthStore
  .getState()
  .loadAuth()
  .then(() =>
    console.log('Successfully loaded Auth Status', useAuthStore.getState()),
  )
  .catch(async (e) => {
    console.error('Error loading Auth Status', e);
    console.info('Deleting Auth Status');
    try {
      await useAuthStore.getState().logout();
      return console.info('Auth Status successfully deleted');
    } catch (e1) {
      return console.error('Error loading Auth Status. Moving on...');
    }
  })
  .finally(() => render());

function render() {
  root.render(
    <AppContext>
      <App />
    </AppContext>,
  );
}
