import React from 'react';
import routes from '../constants/routes.json';
import { AnyType, SpecialJob } from '~/types';
import { DefaultRunningAnyTile } from './defaultUIComponents';
import { momentDurationToHMin } from '~/utils/timeUtils';
import { useEmployeeId } from '~/state/auth';
import {
  useResumeSpecialJob,
  useRunningSpecialJob,
  useStartSpecialJob,
} from '~/api/SpecialJobApi';
import { useNavigate } from 'react-router';

type Props = {
  specialJob: SpecialJob;
  isStartable?: boolean;
};

const SpecialJobTile: React.FC<Props> = ({ specialJob, isStartable }) => {
  const navigate = useNavigate();
  const employeeId = useEmployeeId();

  const startSpecialJobRoute = routes.RUN_SPECIAL_JOBS.replace(
    ':specialJob',
    specialJob.id,
  );

  const onSuccess = () => {
    return navigate(startSpecialJobRoute);
  };

  const { data: runningSpecialJob } = useRunningSpecialJob({
    employeeId,
    specialJobId: Number(specialJob.id),
  });

  const { mutate: startSpecialJob, isPending: startPending } =
    useStartSpecialJob({ onSuccess });
  const { mutate: resumeSpecialJob, isPending: resumePending } =
    useResumeSpecialJob({ onSuccess });
  const isPending = startPending || resumePending;

  const runSpecialJobOnClick = () => {
    if (runningSpecialJob?.status == 'R') {
      return void navigate(startSpecialJobRoute);
    }
    if (runningSpecialJob) {
      return resumeSpecialJob({
        runningSpecialJobId: Number(runningSpecialJob.id),
      });
    }
    startSpecialJob({ specialJobId: Number(specialJob.id) });
  };

  return (
    <DefaultRunningAnyTile
      anyType={AnyType.specialJob}
      name={specialJob.name}
      description={specialJob.description}
      runRunningAny={runSpecialJobOnClick}
      durationString={momentDurationToHMin(specialJob.duration)}
      isStartable={isStartable}
      amount={Math.max(
        specialJob.ordered_amount - specialJob.total_done_amount,
        0,
      )}
      disabled={isPending}
    />
  );
};

export default SpecialJobTile;
