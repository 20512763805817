/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderedProjectDoneRequest
 */
export interface OrderedProjectDoneRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderedProjectDoneRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderedProjectDoneRequest
     */
    comment?: string;
}

/**
 * Check if a given object implements the OrderedProjectDoneRequest interface.
 */
export function instanceOfOrderedProjectDoneRequest(value: object): value is OrderedProjectDoneRequest {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function OrderedProjectDoneRequestFromJSON(json: any): OrderedProjectDoneRequest {
    return OrderedProjectDoneRequestFromJSONTyped(json, false);
}

export function OrderedProjectDoneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderedProjectDoneRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

  export function OrderedProjectDoneRequestToJSON(json: any): OrderedProjectDoneRequest {
      return OrderedProjectDoneRequestToJSONTyped(json, false);
  }

  export function OrderedProjectDoneRequestToJSONTyped(value?: OrderedProjectDoneRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'amount': value['amount'],
        'comment': value['comment'],
    };
}

